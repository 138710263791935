<template>
  <v-container>
    <h1>{{ verb }} Subject</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.subtest_type_id"
              :disabled="loading"
              :items="subtestTypes"
              :loading="subtestTypeLoading"
              :rules="rules.subtestType"
              :search-input.sync="search"
              label="Subtest Type"
              item-text="type"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-text-field
              v-model="form.name"
              :counter="30"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.abbreviation"
              :disabled="loading"
              :rules="rules.abbreviation"
              label="Abbreviation"
              required
            ></v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Subject from "@/services/subject";
import SubtestType from "@/services/subtest_type";

export default {
  name: "Subject.Form",
  data() {
    return {
      valid: false,
      form: {
        subtest_type_id: "",
        abbreviation: "",
        name: ""
      },
      subtestTypes: [],
      rules: {
        subtestType: [v => !!v || "Subtest Type is required"],
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 60) ||
            "Name must be greater than 5 and less then 60",
          v =>
            new RegExp(/^[ A-Za-z0-9_@./#&+-]*$/i).test(v) ||
            "Name must only contain alphanumeric and whitespace characters"
        ],
        abbreviation: [
          v => !!v || "Abbreviation is required",
          v =>
            (v.length >= 1 && v.length <= 5) ||
            "Abbreviation must be greater than 1 and less then 5",
          v =>
            new RegExp(/^[a-zA-Z0-9]*$/i).test(v) ||
            "Abbreviation must only contain alphanumeric characters"
        ]
      },
      search: null,
      model: null,
      subtestTypeLoading: false,
      loading: false
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    }
  },
  watch: {
    search(val) {
      val && val !== this.form.subtest_type_id && this.searchSubtestTypes(val);
    }
  },
  async created() {
    this.setNavbarTitle("Manage Subject");
    if (this.dataId) {
      const subject = await Subject.find(this.dataId);
      const { name, abbreviation, subtestType } = subject.data.data;
      this.form = { name, abbreviation };
      this.subtestTypes.push(subtestType);
      this.form.subtest_type_id = subtestType.id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchSubtestTypes(query) {
      this.subtestTypeLoading = true;
      const subtestTypes = await SubtestType.search(query);
      this.subtestTypes = subtestTypes.data.data.subtestTypes;
      this.subtestTypeLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.dataId) {
            await Subject.update(this.dataId, this.form);
          } else {
            await Subject.create(this.form);
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Subject.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
